let flag = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);//flag位true时为手机端或者电视
export function title_Font(){
  return flag?9:16
}
export function title2_Font(){
  return flag?8:14
}
export function x_Font(){
  return flag?6:12
}
export function y_Font(){
  return flag?6:12
}
export function lineSize(){
  return flag?0.2:0.6
}
export function w_Font(){
  return flag ? 5 : 15
}
export function h_Font(){
  return flag ? 2.5 : 15
}
export function line_h_Font(){
  return flag ? 1: 12
}
export function line_Font(){
  return flag?1:2
}
export function symbolSize_Font(){
  return flag?3:10
}
export function map_Font(){
  return flag?0.85:1
}
export function map_font_size(){
  return flag?6:11
}
export function map_top(){
  return flag?"35":'center'
  // return flag?"10":'10'
}
export function map_font_color(){
  return flag?"#caf982":'#00ffc4'
  // return flag?"10":'10'
}

export function map_period(){
  return flag?8:10
}
export function map_scale(){
  return flag?3:4
}
export function map_shadowBlur(){
  return flag?10:5
}
export function symbolSize(){
  return flag?20:null
}


// 中国地图tip轮播显示
export function mapTip(params) {
  var tipHtml = "";
  tipHtml = `<div style="width:15vh;;font-size:1vh;line-height:1.2vh;color:#fff; border-radius: 0.5vh;" >
      <div style="height:3.2vh;line-height:3.2vh;width:100%;border-bottom:1px solid #fff;text-align:center;font-weight:600;font-size:1.35vh">
        ${params.name}
      </div>

      <div style="width:100%">
        <div style="padding:0.8vh 1vh">
          <span>运营中</span>
            <span style="float:right;color:#ff6b6b;font-weight:600;font-size:1.2vh">${params.data.operation}</span>
        </div>
      </div>

      <div style="width:100%">
        <div style="padding:0.8vh 1vh">
          <span>试业中</span>
            <span style="float:right;color:#ff6b6b;font-weight:600;font-size:1.2vh">${params.data.tryIndustry}</span>
        </div>
      </div>

      <div style="width:100%">
        <div style="padding:0.8vh 1vh">
          <span>待开业</span>
            <span style="float:right;color:#ff6b6b;font-weight:600;font-size:1.2vh">${params.data.toBeOpened}</span>
          </div>
      </div>
      
      <div style="width:100%">
        <div style="padding:0.8vh 1vh">
          <span>筹建中</span>
            <span style="float:right;color:#ff6b6b;font-weight:600;font-size:1.2vh">${params.data.underConstruction}</span>
        </div>
      </div>
    </div>`;
  return tipHtml;
}

export function mapTip2(params) {
  var tipHtml = "";
  tipHtml = `<div style="width:15vh;;font-size:1vh;line-height:1.2vh;color:#fff; border-radius: 0.5vh;" >
      <div style="height:3.2vh;line-height:3.2vh;width:100%;border-bottom:1px solid #fff;text-align:center;font-weight:600;font-size:1.35vh">
        ${params.name}
      </div>

      <div style="width:100%">
        <div style="padding:0.8vh 1vh">
          <span>运营分店数</span>
            <span style="float:right;color:#ff6b6b;font-weight:600;font-size:1.2vh">${params.data.operation}</span>
        </div>
      </div>

      <div style="width:100%">
        <div style="padding:0.8vh 1vh">
          <span>客房数</span>
            <span style="float:right;color:#ff6b6b;font-weight:600;font-size:1.2vh">${params.data.totalRoom}</span>
        </div>
      </div>

      <div style="width:100%">
        <div style="padding:0.8vh 1vh">
          <span>取电房间数</span>
            <span style="float:right;color:#ff6b6b;font-weight:600;font-size:1.2vh">${params.data.electricRoom}</span>
          </div>
      </div>
    </div>`;
  return tipHtml;
}
// export function titleFont(){
//   return flag?9:16
// }
