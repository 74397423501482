var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "demo" }, [
    _c("div", { staticClass: "boxPd" }, [
      _c("div", { staticClass: "enterDetalis_title" }, [
        _vm._v("在住房间数量"),
      ]),
      _c(
        "div",
        { staticClass: "customer" },
        [
          _c(
            "dv-decoration-9",
            {
              staticStyle: { height: "100%", width: "100%" },
              attrs: { dur: 25 },
            },
            [
              _c("count-to", {
                staticClass: "showNum",
                staticStyle: { "font-size": "3.5vh" },
                attrs: {
                  "start-val": _vm.initTotal * _vm.times,
                  "end-val": _vm.total * _vm.times,
                  duration: 3000,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "take instotal" }, [
        _c(
          "div",
          { staticClass: "Num" },
          [
            _c(
              "dv-decoration-9",
              {
                staticStyle: { height: "5vh", width: "15vh" },
                attrs: { dur: 15 },
              },
              [
                _c("count-to", {
                  staticClass: "showNum",
                  attrs: {
                    "start-val": _vm.initElectricity.manage * _vm.times,
                    "end-val": _vm.roomElectricity.manage * _vm.times,
                    duration: 3000,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("span", { staticClass: "titleNum" }, [_vm._v("内部取电")]),
      ]),
      _c("div", { staticClass: "take ins" }, [
        _c(
          "div",
          { staticClass: " Num" },
          [
            _c(
              "dv-decoration-9",
              {
                staticStyle: { height: "6vh", width: "15vh" },
                attrs: { dur: 15 },
              },
              [
                _c("count-to", {
                  staticClass: "showNum",
                  attrs: {
                    "start-val": _vm.initElectricity.guest * _vm.times,
                    "end-val": _vm.roomElectricity.guest * _vm.times,
                    duration: 3000,
                  },
                }),
              ],
              1
            ),
            _c("span", { staticClass: "titleNum" }, [_vm._v("客人取电")]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }