<template>
  <div class="demo">
  <!-- <dv-border-box-1> -->
    
    <div class="boxPd">
      <div class="enterDetalis_title">在住房间数量</div>
      <div class="customer">
        <!-- <span class="titleNum">取电总数</span> -->
          <dv-decoration-9 style="height: 100%;width:100%;" :dur="25">
            <count-to   style="font-size:3.5vh" :start-val="initTotal*times" :end-val="total*times" :duration="3000" class="showNum" />
          </dv-decoration-9>
      </div>
      <div class="take instotal">
        <div class="Num">
          <dv-decoration-9 style="height: 5vh;width: 15vh;" :dur="15">
            <count-to
              :start-val="initElectricity.manage*times"
              :end-val="roomElectricity.manage*times"
              :duration="3000"
              class="showNum"
            />
          </dv-decoration-9>
        </div>
        <span class="titleNum">内部取电</span>
      </div>
      <div class="take ins">
        <div class=" Num">
          <dv-decoration-9 style="height:6vh;width:15vh;"  :dur="15">
            <count-to
            class="showNum"
              :start-val="initElectricity.guest*times"
              :end-val="roomElectricity.guest*times"
              :duration="3000"
            />
          </dv-decoration-9>
             <span class="titleNum">客人取电</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CountTo from "vue-count-to";
import { GET_ROOM_ELECTRICITY } from "@/api";
export default {
   props: {
    roleType: {
      type: String,
      default: "0",
    },
  },
  components: {
    CountTo,
  },
  data() {
    return {
      roomElectricity: {
        guest: 0,
        manage: 0,
      },
      initElectricity: {
        guest: 0,
        manage: 0,
      },
    };
  },
  computed: {
    times(){
      return this.$store.state.times
    },
    total() {
      let val = this.roomElectricity.guest + this.roomElectricity.manage;
      return isNaN(val) ? 0 : val;
    },
    initTotal() {
      let val = this.initElectricity.guest + this.initElectricity.manage;
      return isNaN(val) ? 0 : val;
    },
  },
  created() {
    this.getElectricity();
    this.roomsTop = null;
    this.roomsTop = setInterval(() => {
      this.getElectricity();
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.roomsTop);
  },
  methods: {
    // 获取取电总数
    async getElectricity(data={roleType:this.roleType}) {
      let res = await GET_ROOM_ELECTRICITY(data);
      this.roomElectricity.guest = res.data.data.guest?res.data.data.guest:0;
      this.roomElectricity.manage = res.data.data.manage?res.data.data.manage:0;
      setTimeout(() => {
        this.initElectricity.guest = res.data.data.guest?res.data.data.guest:0;
        this.initElectricity.manage = res.data.data.manage?res.data.data.manage:0;
      }, 2000);
    },
  },
};
</script>




<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
.enterDetalis_title{
  font-size: 1.8vh;
  color: #fff;
}
.showNum{
  position: relative;
  z-index: 999;
}
.demo {
  position: relative;
  header {
    position: absolute;
    top: 1.5vh;
    left: 0.8vw;
    font-size: 1.7vh;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
  }
  .take {
    color: #fff;
    font-size: 2vh;
  }
  .ins {
    display: flex;
    position: absolute;
    top: 5vh;
    right: 0.5vw;
    
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
    // font-size: 1.5vh;
  }
  .instotal {
    height: 5vh;
   position: absolute;
    top: 5vh;
    left: 0.5vw;
    display: flex;
    align-items: center;
     flex-direction: column;
  }
  
  .customer {
   height: 10vh;
   width: 70%;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    bottom: 1vh;
    left: 50%;
    transform: translate(-50%);
  }
}
 .titleNum {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 1.2vh;
  }
</style>
